import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductStatus = _resolveComponent("ProductStatus")!
  const _component_ProductStatusForm = _resolveComponent("ProductStatusForm")!
  const _component_a_collapse_panel = _resolveComponent("a-collapse-panel")!
  const _component_a_collapse = _resolveComponent("a-collapse")!

  return (_openBlock(), _createBlock(_component_a_collapse, {
    activeKey: _ctx.activeKey,
    "onUpdate:activeKey": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.activeKey) = $event)),
    class: "product-status-list",
    "expand-icon-position": "right"
  }, {
    expandIcon: _withCtx(({ isActive }) => [
      _createElementVNode("img", {
        src: _ctx.ArrowIcon,
        alt: "arrow",
        style: _normalizeStyle({
          transform: isActive ? 'rotate(90deg)' : 'rotate(0)',
          top: '20px',
          right: '20px',
          fontWeight: 'bold',
        })
      }, null, 12, _hoisted_1)
    ]),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productList, (product) => {
        return (_openBlock(), _createBlock(_component_a_collapse_panel, {
          key: product.id
        }, {
          header: _withCtx(() => [
            _createVNode(_component_ProductStatus, {
              "icon-src": product.iconSrc,
              "product-name": product.productName,
              authorized: product.authorized,
              "last-visit": product.lastVisit
            }, null, 8, ["icon-src", "product-name", "authorized", "last-visit"])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_ProductStatusForm, {
              product: product,
              "product-status": product.productStatus,
              "loading-props": _ctx.loading || _ctx.updateOauthSessionLoading,
              onChangeForm: _ctx.handleUpdateSession,
              onTerminate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('terminate', $event)))
            }, null, 8, ["product", "product-status", "loading-props", "onChangeForm"])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }, 8, ["activeKey"]))
}