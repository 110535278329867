import { dateFromNowPretty } from "@/shared/utils/date";
import sortBy from "lodash/sortBy";

/**
 * Session List
 */
export const sessionListFormatter = (sessionList) =>
  sessionList.map((session) => {
    // Parse Date
    const parsedDate = new Date(session.lastVisited);

    return {
      ...session,
      id: session.id,
      iconSrc: session.application?.logo40,
      productName: session.application?.name,
      authorized: !session.isTerminated,
      lastVisit: dateFromNowPretty(parsedDate),
      channelEmail: session.notificationChannel?.id,
      productStatus: session.allowedNotificationTypes,
      dataChannelId: session?.dataChannel?.id,
    };
  });

/**
 * Session List - Channel Dropdown
 */
export const channelListFormatter = (channel) => {
  // Sort Channel Options by typename
  const sortedChannel = sortBy(channel, ["__typename"]);

  // Return Channel options with label and value
  return sortedChannel.map((channel) => {
    return {
      label: channel.value,
      value: channel.id,
    };
  });
};
