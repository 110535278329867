
import { defineComponent, PropType, ref } from "vue";
import ProductStatus from "./ProductStatus.vue";
import ProductStatusForm from "./ProductStatusForm.vue";
import ArrowIcon from "@/assets/icons/arrow.svg";
import { useSessions } from "@/shared/composables/Sessions/useSessions";
import noop from "lodash/noop";

/**
 * TODO: This component can be used as an example for the session list
 * If that's the case delete this file if not needed anymore or delete this comment if this will be used as is
 */
export default defineComponent({
  components: { ProductStatus, ProductStatusForm },
  props: {
    productList: {
      type: Array as PropType<unknown[]>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["terminate"],
  setup() {
    const activeKey = ref([]);

    const { handleOauthUpdateSession, updateOauthSessionLoading } =
      useSessions(false);

    /**
     * Update Session
     */
    const handleUpdateSession = async (form, callbackOnError = noop) => {
      try {
        /**
         * TODO: Update when API endpoint already
         * accepts channel dropdown update
         */
        await handleOauthUpdateSession({
          sessionId: form.sessionId,
          allowedNotificationTypes: form.selectedOptionValue,
          enableNotification: form.enableNotificationValue,
          notificationChannelId: form.notificationChannelId,
          dataChannelId: form.dataChannelId,
        });
      } catch (error) {
        // use callback to handle error, since this method is called with emit
        if (error) {
          callbackOnError(error);
        }
        console.log("Error Updating OAuth on Sessions", error);
      }
    };

    return {
      activeKey,
      ArrowIcon,
      handleUpdateSession,
      updateOauthSessionLoading,
    };
  },
});
