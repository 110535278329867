import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_CommonCardLayout = _resolveComponent("CommonCardLayout")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_a_skeleton, {
        key: 0,
        active: "",
        paragraph: { rows: 4 },
        loading: _ctx.loading
      }, null, 8, ["loading"]))
    : (_openBlock(), _createBlock(_component_CommonCardLayout, {
        key: 1,
        class: "empty-session py-6 text-center"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_typography_title, {
            level: 4,
            class: "text-center lighter"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("You don't have active sessions now.")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_typography_paragraph, {
            class: "sub-text text-left lighter m-auto",
            style: {"max-width":"33rem"}
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t(
          "They will appear when you log in with {merchantBrand} account somewhere",
          {
            merchantBrand: _ctx.config.merchantBrand,
          }
        )), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
}