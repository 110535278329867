import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "session-product-status flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlatformIcon = _resolveComponent("PlatformIcon")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_alert = _resolveComponent("a-alert")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PlatformIcon, {
      class: "session-product-status__logo",
      name: _ctx.productName,
      logo: _ctx.iconSrc,
      "fallback-initial-icon": ""
    }, null, 8, ["name", "logo"]),
    _createVNode(_component_a_typography_paragraph, { class: "font-semibold my-0 mx-3" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.productName), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_a_alert, {
      class: "py-0 px-2",
      message: _ctx.statusMessage,
      type: _ctx.statusType,
      banner: "",
      "show-icon": false
    }, null, 8, ["message", "type"]),
    _createVNode(_component_a_typography_paragraph, { class: "text-sm ml-auto mr-10 my-0 lighter" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("Last visit {lastVisit}", { lastVisit: _ctx.lastVisit })), 1)
      ]),
      _: 1
    })
  ]))
}