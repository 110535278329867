import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductStatusList = _resolveComponent("ProductStatusList")!
  const _component_EmptySession = _resolveComponent("EmptySession")!
  const _component_PageLayout = _resolveComponent("PageLayout")!

  return (_openBlock(), _createBlock(_component_PageLayout, {
    title: _ctx.t('Sessions')
  }, {
    default: _withCtx(() => [
      (_ctx.sessionList?.length > 0)
        ? (_openBlock(), _createBlock(_component_ProductStatusList, {
            key: 0,
            loading: _ctx.loading,
            "product-list": _ctx.sessionList,
            onTerminate: _ctx.handleTerminate
          }, null, 8, ["loading", "product-list", "onTerminate"]))
        : (_openBlock(), _createBlock(_component_EmptySession, {
            key: 1,
            loading: _ctx.loading
          }, null, 8, ["loading"]))
    ]),
    _: 1
  }, 8, ["title"]))
}