
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import PlatformIcon from "@/shared/components/Icons/PlatformIcon.vue";

export default defineComponent({
  components: { PlatformIcon },
  props: {
    iconSrc: {
      type: String,
      default: "",
    },
    productName: {
      type: String,
      default: "",
    },
    authorized: {
      default: false,
    },
    lastVisit: {
      type: String,
    },
  },
  setup(props) {
    const { t } = useI18n();

    return {
      t,
      statusType: computed(() => {
        if (props.authorized) return "success";
        return "info";
      }),
      statusMessage: computed(() => {
        if (props.authorized) return t("Authorized");
        return t("Deactivated");
      }),
    };
  },
});
