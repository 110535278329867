
import PageLayout from "@/shared/components/Layouts/PageLayout.vue";
import ProductStatusList from "@/shared/components/Products/ProductStatusList.vue";
import EmptySession from "@/web/views/Sessions/EmptySession.vue";
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useSessions } from "@/shared/composables/Sessions/useSessions";
import { sessionListFormatter } from "@/web/utils/sessionsUtils";
import { message } from "ant-design-vue";

export default defineComponent({
  components: {
    PageLayout,
    ProductStatusList,
    EmptySession,
  },
  setup() {
    const { t } = useI18n();

    const {
      oauthSessionsList,
      loading,
      updateOauthSessionLoading,
      handleTerminateOauthSession,
      terminateOauthSessionLoading,
      handleRefetchOauthSessions,
    } = useSessions();

    /**
     * Terminate Session
     */
    const handleTerminate = async (sessionId: string) => {
      try {
        await handleTerminateOauthSession({ sessionId });

        message.info(t("Session Successfully Terminated."));

        // Refetch Session List
        handleRefetchOauthSessions();
      } catch (error) {
        console.log("Error Terminate on Sessions", error);
      }
    };

    return {
      t,
      sessionList: computed(() =>
        sessionListFormatter(oauthSessionsList.value)
      ),
      handleTerminate,
      loading: computed(
        () =>
          loading.value ||
          terminateOauthSessionLoading.value ||
          updateOauthSessionLoading.value
      ),
    };
  },
});
