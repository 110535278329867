import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex items-end" }
const _hoisted_2 = { class: "flex-1" }
const _hoisted_3 = { class: "font-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_InputCheckbox = _resolveComponent("InputCheckbox")!
  const _component_InputDropdown = _resolveComponent("InputDropdown")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_ConfirmModal = _resolveComponent("ConfirmModal")!
  const _component_FormGroup = _resolveComponent("FormGroup")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_a_skeleton, {
        key: 0,
        active: "",
        paragraph: { rows: 4 },
        loading: _ctx.loading
      }, null, 8, ["loading"]))
    : (_openBlock(), _createBlock(_component_FormGroup, {
        key: 1,
        class: "product-status-form",
        "wrapper-col": { span: 24 },
        layout: "vertical",
        loading: _ctx.loadingProps
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_InputSwitch, {
                value: _ctx.enableNotification,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.enableNotification) = $event)),
                label: _ctx.t('Notifications Enabled'),
                disabled: _ctx.loadingProps
              }, null, 8, ["value", "label", "disabled"]),
              _createVNode(_component_InputCheckbox, {
                value: _ctx.selectedOption,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedOption) = $event)),
                multiple: "",
                label: _ctx.t('Notify about'),
                options: _ctx.notificationOptions,
                disabled: _ctx.loadingProps || !_ctx.enableNotification
              }, null, 8, ["value", "label", "options", "disabled"]),
              _createVNode(_component_InputDropdown, {
                value: _ctx.selectedChannel,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedChannel) = $event)),
                label: _ctx.t('Notification Channel'),
                options: _ctx.channelOptions,
                disabled: _ctx.loadingProps
              }, null, 8, ["value", "label", "options", "disabled"]),
              _createVNode(_component_InputDropdown, {
                value: _ctx.dataChannelId,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dataChannelId) = $event)),
                label: _ctx.t('Data Channel'),
                options: _ctx.domainChannelOptions,
                disabled: _ctx.loadingProps
              }, null, 8, ["value", "label", "options", "disabled"])
            ]),
            _createVNode(_component_ConfirmModal, {
              "modal-class": "modal-terminate",
              title: _ctx.t('Terminate'),
              type: _ctx.ConfirmModalTypes.danger,
              onOk: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('terminate', _ctx.product.id)))
            }, {
              trigger: _withCtx(({ open }) => [
                _createVNode(_component_a_button, {
                  danger: true,
                  size: "small",
                  loading: _ctx.loadingProps,
                  disabled: _ctx.product.isTerminated,
                  onClick: open
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("Terminate")), 1)
                  ]),
                  _: 2
                }, 1032, ["loading", "disabled", "onClick"])
              ]),
              "custom-body": _withCtx(() => [
                _createVNode(_component_i18n_t, {
                  keypath: "Are you sure you want to terminate {productName}?",
                  tag: "span"
                }, {
                  productName: _withCtx(() => [
                    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.product.productName), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["title", "type"])
          ])
        ]),
        _: 1
      }, 8, ["loading"]))
}