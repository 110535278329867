
import { computed, defineComponent, PropType, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import ConfirmModal, {
  ConfirmModalTypes,
} from "@/shared/components/Modals/ConfirmModal.vue";
import FormGroup from "../Forms/FormGroup.vue";
import InputCheckbox from "../Forms/InputCheckbox.vue";
import InputDropdown from "@/shared/components/Forms/InputDropdown.vue";
import { useCommunicationChannelList } from "@/shared/composables/CommunicationChannels/useCommunicationChannelList";
import { channelListFormatter } from "@/web/utils/sessionsUtils";
import InputSwitch from "@/shared/components/Forms/InputSwitch.vue";
import { UpdateOauthSession_updateOauthSession_ResponseErrors } from "@/api/sessions/__generated__/UpdateOauthSession";
import { apiErrorCodes } from "@/shared/utils/constants";
import { NotificationPurpose } from "../../../../__generated__/globalTypes";

export default defineComponent({
  components: {
    InputCheckbox,
    FormGroup,
    ConfirmModal,
    InputDropdown,
    InputSwitch,
  },
  props: {
    loadingProps: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      required: true,
    },
    productStatus: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  emits: ["change-form", "terminate"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const selectedOption = ref(props.productStatus);
    const selectedChannel = ref(props.product?.channelEmail); // this is notificationChannelId, not sure why it's named selectedChannel but will not update anymore
    const enableNotification = ref(props.product?.enableNotification);
    const dataChannelId = ref(props.product?.dataChannelId);

    const { channelList, loading } = useCommunicationChannelList({
      isVerified: true,
    });

    //  Channel Options
    const channelOptions = computed(() =>
      channelListFormatter(
        channelList.value.filter(
          (item) =>
            item?.__typename == "MobileChannel" ||
            item?.__typename == "EmailChannel"
        )
      )
    );

    const domainChannelOptions = computed(() =>
      channelListFormatter(
        channelList.value.filter((item) => item?.__typename == "DomainChannel")
      )
    );

    // Watch value change
    watch(
      [selectedOption, selectedChannel, enableNotification, dataChannelId],
      ([
        selectedOptionValue,
        notificationChannelId,
        enableNotificationValue,
        dataChannelIdValue,
      ]) => {
        emit(
          "change-form",
          {
            sessionId: props?.product?.id,
            selectedOptionValue,
            notificationChannelId,
            enableNotificationValue,
            dataChannelId: dataChannelIdValue,
          },
          (
            errors: UpdateOauthSession_updateOauthSession_ResponseErrors["errors"]
          ) => {
            if (
              errors?.find(
                (err) => err?.code === apiErrorCodes.CALLBACK_URL_NOT_AVAILABLE
              )
            ) {
              /**
               * This error if a data channel doesn't have a callback URL
               * Reset value to original one if there's an error
               */
              dataChannelId.value = props.product?.dataChannelId;
            }
          }
        );
      }
    );

    return {
      t,
      selectedOption,
      selectedChannel,
      notificationOptions: [
        { label: t("Transaction"), value: NotificationPurpose.TRANSACTION },
        { label: t("Promo"), value: NotificationPurpose.PROMOTION },
        { label: t("Data"), value: NotificationPurpose.DATA },
      ],
      channelList,
      channelOptions,
      domainChannelOptions,
      enableNotification,
      dataChannelId,
      loading,
      ConfirmModalTypes,
    };
  },
});
